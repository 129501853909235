const characterList = [
  {
    id: 'mario',
    cn: '马力欧'
  },
  {
    id: 'donkey',
    cn: '森喜刚'
  },
  {
    id: 'link',
    cn: '林克'
  },
  {
    id: 'samus',
    cn: '萨姆斯'
  },
  {
    id: 'samusd',
    cn: '黑暗萨姆斯'
  },
  {
    id: 'yoshi',
    cn: '耀西'
  },
  {
    id: 'kirby',
    cn: '卡比'
  },
  {
    id: 'fox',
    cn: '火狐'
  },
  {
    id: 'pikachu',
    cn: '皮卡丘'
  },
  {
    id: 'luigi',
    cn: '路易吉'
  },
  {
    id: 'ness',
    cn: '奈斯'
  },
  {
    id: 'captain',
    cn: '飞隼队长'
  },
  {
    id: 'purin',
    cn: '胖丁'
  },
  {
    id: 'peach',
    cn: '桃花公主'
  },
  {
    id: 'daisy',
    cn: '菊花公主'
  },
  {
    id: 'koopa',
    cn: '酷霸王'
  },
  {
    id: 'ice_climber',
    cn: '翻越冰山者'
  },
  {
    id: 'sheik',
    cn: '希克'
  },
  {
    id: 'zelda',
    cn: '塞尔达'
  },
  {
    id: 'mariod',
    cn: '马力欧医生'
  },
  {
    id: 'pichu',
    cn: '皮丘'
  },
  {
    id: 'falco',
    cn: '佛克'
  },
  {
    id: 'marth',
    cn: '马尔斯'
  },
  {
    id: 'lucina',
    cn: '露琪娜'
  },
  {
    id: 'younglink',
    cn: '幼年林克'
  },
  {
    id: 'ganon',
    cn: '盖侬多夫'
  },
  {
    id: 'mewtwo',
    cn: '超梦'
  },
  {
    id: 'roy',
    cn: '罗伊'
  },
  {
    id: 'chrom',
    cn: '库洛姆'
  },
  {
    id: 'gamewatch',
    cn: 'Mr. Game & Watch'
  },
  {
    id: 'metaknight',
    cn: '魅塔骑士'
  },
  {
    id: 'pit',
    cn: '彼特'
  },
  {
    id: 'pitb',
    cn: '黑暗彼特'
  },
  {
    id: 'szerosuit',
    cn: '零装甲萨姆斯'
  },
  {
    id: 'wario',
    cn: '瓦力欧'
  },
  {
    id: 'snake',
    cn: 'Snake'
  },
  {
    id: 'ike',
    cn: '艾克'
  },
  {
    id: 'ptrainer',
    cn: '宝可梦训练家'
  },
  {
    id: 'diddy',
    cn: '迪迪刚'
  },
  {
    id: 'lucas',
    cn: '卢卡斯'
  },
  {
    id: 'sonic',
    cn: '索尼克'
  },
  {
    id: 'dedede',
    cn: '帝帝帝大王'
  },
  {
    id: 'pikmin',
    cn: '皮克敏&欧力马'
  },
  {
    id: 'lucario',
    cn: '路卡利欧'
  },
  {
    id: 'robot',
    cn: '机器人'
  },
  {
    id: 'toonlink',
    cn: '卡通林克'
  },
  {
    id: 'wolf',
    cn: '沃鲁夫'
  },
  {
    id: 'murabito',
    cn: '村民'
  },
  {
    id: 'rockman',
    cn: '洛克人'
  },
  {
    id: 'wiifit',
    cn: 'Wii Fit教练'
  },
  {
    id: 'rosetta',
    cn: '罗莎塔&琪琪'
  },
  {
    id: 'littlemac',
    cn: '小麦克'
  },
  {
    id: 'gekkouga',
    cn: '甲贺忍蛙'
  },
  {
    id: 'palutena',
    cn: '帕露蒂娜'
  },
  {
    id: 'pacman',
    cn: '吃豆人'
  },
  {
    id: 'reflet',
    cn: '鲁弗莱'
  },
  {
    id: 'shulk',
    cn: '修尔克'
  },
  {
    id: 'koopajr',
    cn: '酷霸王Jr.'
  },
  {
    id: 'duckhunt',
    cn: '打猎'
  },
  {
    id: 'ryu',
    cn: '隆'
  },
  {
    id: 'ken',
    cn: '肯'
  },
  {
    id: 'cloud',
    cn: '克劳德'
  },
  {
    id: 'kamui',
    cn: '神威'
  },
  {
    id: 'bayonetta',
    cn: 'Bayonetta'
  },
  {
    id: 'inkling',
    cn: 'Inkling'
  },
  {
    id: 'ridley',
    cn: '利德雷'
  },
  {
    id: 'simon',
    cn: 'Simon'
  },
  {
    id: 'richter',
    cn: 'Richter'
  },
  {
    id: 'krool',
    cn: '库鲁鲁王'
  },
  {
    id: 'shizue',
    cn: '西施惠'
  },
  {
    id: 'gaogaen',
    cn: '炽焰咆哮虎'
  },
  {
    id: 'packun',
    cn: '吞食花'
  },
  {
    id: 'jack',
    cn: 'Joker'
  },
  {
    id: 'brave',
    cn: '勇者'
  },
  {
    id: 'buddy',
    cn: '阿邦&阿卡'
  },
  {
    id: 'dolly',
    cn: '特瑞'
  },
  {
    id: 'master',
    cn: '贝雷特/贝雷丝'
  },
  {
    id: 'tantan',
    cn: '面面'
  },
  {
    id: 'pickel',
    cn: '史蒂夫/艾莉克斯'
  },
  {
    id: 'edge',
    cn: '萨菲罗斯'
  },
  {
    id: 'eflame',
    cn: '焰/光'
  },
  {
    id: 'demon',
    cn: '一八'
  },
  {
    id: 'trail',
    cn: '索拉'
  },
  {
    id: 'miifighter',
    cn: 'Mii 格斗类型'
  },
  {
    id: 'miiswordsman',
    cn: 'Mii 剑术类型'
  },
  {
    id: 'miigunner',
    cn: 'Mii 射击类型'
  }
]

export default characterList