<template>
  <img v-lazy="getSrc" :style="{width: width, height: width}"/>
</template>

<script>
import CharacterSquare from './SquareIcon.js'
export default {
  ...CharacterSquare
}
</script>

<style scoped>

</style>