<template>
  <div class="parallel-icon" :style="{width: `${width}px`, height: `${width * 2}px`}">
    <div class="wrapper">
      <square-icon :character="character" :color="color" :width="`${width * 2}px`"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParallelIcon',
  props: {
    character: String,
    color: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 60
    }
  }
}
</script>

<style lang="less" scoped>
.parallel-icon {
  position: relative;
  -moz-transform:skewX(-21.8deg);
  -webkit-transform:skewX(-21.8deg);
  -o-transform:skewX(-21.8deg);
  transform:skewX(-21.8deg); 
  overflow: hidden;
  background-color: #1D1D1D;
  border: 1px solid #808080;
  .wrapper {
    position: relative;
    left: -50%;
    -moz-transform:skewX(21.8deg);
    -webkit-transform:skewX(21.8deg);
    -o-transform:skewX(21.8deg);
    transform:skewX(21.8deg); 
    img {
      position: absolute;
      top: 0;
      left: -40%;
    }
  }
}
</style>