export default {
  name: 'Pagination',
  props: {
    current: Number,
    total: Number,
    pageSize: {
      type: Number,
      default: 10
    },
    offset: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      paginationList: []
    }
  },
  mounted () {
    this.buildPagination()
  },
  methods: {
    buildPagination: function () {
      let current = this.current
      let total = Math.ceil(this.total / this.pageSize)
      let paginationList = []
      let head, tail;
      for (var off = 0; off <= this.offset; off ++) {
        if (current + off <= total) tail = current + off
        if (current - off >= 1) head = current - off
      }
      if (head != 1) paginationList.push(1)
      if (head > 2) paginationList.push(-1)
      for (var p = head; p <= tail; p ++) {
        paginationList.push(p)
      }
      if (total - tail > 1) paginationList.push(-1)
      if (total != tail && total != 1) paginationList.push(total)
      this.paginationList = paginationList
    },
    pageChange: function (val) {
      this.$emit('change', val)
    },
    prevPage: function () {
      if (this.current != 1) {
        this.$emit('change', this.current - 1)
      }
    },
    nextPage: function () {
      if (this.current != this.total) {
        this.$emit('change', this.current + 1)
      }
    }
  },
  watch: {
    current: function() {
      this.buildPagination()
    },
    total: function () {
      this.buildPagination()
    }
  }
}