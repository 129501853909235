import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// i18n
import i18n from 'vue-i18n'

// Data
import data from '@/data'
Vue.prototype.$commonData = data

// Cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// 全局注册资源
import CustomIcon from '@/resource/CustomIcon/CustomIcon.vue'
import StockIcon from '@/resource/StockIcon/StockIcon.vue'
import BigIcon from '@/resource/BigIcon/BigIcon.vue'
import WideIcon from '@/resource/WideIcon/WideIcon.vue'
import NationFlag from '@/resource/NationFlag/NationFlag.vue'
import SquareIcon from '@/resource/SquareIcon/SquareIcon.vue'
import StageImg from '@/resource/StageImg/StageImg.vue'
import DiamondIcon from '@/resource/DiamondIcon/DiamondIcon.vue'
import LongIcon from '@/resource/LongIcon/LongIcon.vue'
import Badge from '@/resource/Badge/Badge.vue'

// resources
Vue.component('custom-icon', CustomIcon)
Vue.component('stock-icon', StockIcon)
Vue.component('big-icon', BigIcon)
Vue.component('wide-icon', WideIcon)
Vue.component('nation-flag', NationFlag)
Vue.component('square-icon', SquareIcon)
Vue.component('stage-img', StageImg)
Vue.component('diamond-icon', DiamondIcon)
Vue.component('long-icon', LongIcon)
Vue.component('badge', Badge)

// 全局注册组件
import CustomKeyValue from '@/components/CustomKeyValue/CustomKeyValue.vue'
Vue.component('custom-key-value', CustomKeyValue)
import ParallelIcon from '@/components/ParallelIcon/ParallelIcon.vue'
Vue.component('parallel-icon', ParallelIcon)
import Pagination from '@/components/Pagination/Pagination.vue'
Vue.component('pagination', Pagination)
import Loading from '@/components/Loading/Loading.vue'
Vue.component('loading', Loading)
import CustomTitle from '@/components/CustomTitle/CustomTitle'
Vue.component('custom-title', CustomTitle)

// 文件基地址
import { baseUrl } from '@/api/base'
Vue.prototype.$baseUrl = 'http://mate.cnsmash.com'

// rxjs
import VueRx from 'vue-rx'
Vue.use(VueRx)

// antd
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less';
Vue.use(Antd)

// v-chart
import VCharts from 'v-charts'
Vue.use(VCharts)

// 懒加载
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1
})

// clipper
import VuejsClipper from 'vuejs-clipper/dist/vuejs-clipper.umd.min'
import 'vuejs-clipper/dist/vuejs-clipper.css'
Vue.use(VuejsClipper)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
