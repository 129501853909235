<template>
  <img :src="getSrc" :style="{width: width}" />
</template>

<script>
export default {
  name: 'StageImg',
  props: {
    stage: String,
    width: {
      type: String,
      default: '40px'
    }
  },
  computed: {
    getSrc: function () {
      if (this.stage) {
        let stage = this.$commonData.stageList.find(stage => stage.id == this.stage)
        return `/stages/${stage.file}.png`
      }
      return '/stages/emptystage.png';
    }
  }
}
</script>

<style lang="less" scoped>

</style>