<template>
  <img v-lazy="getSrc" :style="{width: width}"/>
</template>

<script>
import WideIcon from './WideIcon.js'
export default {
  ...WideIcon
}
</script>

<style scoped>

</style>