export function compareTime(time1, time2) {
  let com1 = Date.parse(safariTime(time1))
  let com2 = Date.parse(safariTime(time2))
  return com1.valueOf() > com2.valueOf() ? 1 : 0
}

export function compareNow(time) {
  let now = new Date()
  let com = Date.parse(safariTime(time))
  return com > now.valueOf() ? 1 : 0
}

export function format(date) {
  let month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1)
  let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  let hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  let minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  let second = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
  return `${date.getFullYear()}-${month}-${day} ${hour}:${minute}:${second}`
}

export function getTimeGap(dateTime) {
  let now = (new Date()).valueOf()
  let com = Date.parse(safariTime(dateTime))
  // let com = Date.parse(dateTime)
  let dis = Math.floor((now - com) / 1000)
  let txt = dis > 0 ? '前' : '后'
  dis = Math.abs(dis)
  if (dis < 60) {
    return `刚刚`
  }
  dis = (dis - dis % 60) / 60
  if (dis < 60) {
    return `${dis}分${txt}`
  }
  dis = (dis - dis % 60) / 60
  if (dis < 24) {
    return `${dis}小时${txt}`
  }
  dis = (dis - dis % 24) / 24
  return `${dis}天${txt}`
}

export function safariTime(time) {
  let t = time.split(' ')
  return t.length == 2 ? `${t[0]}T${t[1]}` : t[0]
}