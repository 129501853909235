import { render, staticRenderFns } from "./StageImg.vue?vue&type=template&id=8f8c39b8&scoped=true&"
import script from "./StageImg.vue?vue&type=script&lang=js&"
export * from "./StageImg.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f8c39b8",
  null
  
)

export default component.exports