export default {
  name: 'SquareIcon',
  props: {
    character: String,
    color: {
      type: Number,
      default: 0
    },
    width: {
      String,
      default: '20px'
    }
  },
  computed: {
    getSrc: function () {
      return `http://image.cnsmash.com/squareicon/chara_1_${this.character || 'random'}_0${this.color}.png`
    }
  }
}