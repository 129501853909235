<template>
  <a-tooltip placement="bottomLeft">
    <template slot="title">
      {{badge.note}}
    </template>
    <img :src="getSrc" :style="{width: width, height: width}"/>
  </a-tooltip>
</template>

<script>
import Badge from './Badge.js'
export default {
  ...Badge
}
</script>

<style scoped>

</style>