/***** 静态数据 *****/
import nationList from './nationList.js'
import characterList from './characterList.js'
import fighterSeries from './fighterSeries.js'
import bannerBackground from './bannerBackground.js'
import chartRichCharacter from './chartRichCharacter.js'
import stageList from './stageList.js'
import serverType from './serverType.js'

export default {
  /***** 静态数据 *****/
  nationList: nationList,
  characterList: characterList,
  fighterSeries: fighterSeries,
  bannerBackground: bannerBackground,
  chartRichCharacter: chartRichCharacter,
  stageList: stageList,
  serverType: serverType
}