<template>
  <div class="custom-title">
    <div class="wrapper" :style="{'height': height, 'width': width}">
      <div class="title" :style="{'line-height': height}">{{text}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomTitle',
  props: {
    text: String,
    height: {
      type: String,
      default: '54px'
    },
    width: {
      type: String,
      default: '100%'
    },
    left: {
      type: Boolean,
      default: true
    },
    right: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="less" scoped>
.custom-title {
  .wrapper {
    background-color: #FF3B3B;
    -moz-transform:skewX(-21.8deg);
    -webkit-transform:skewX(-21.8deg);
    -o-transform:skewX(-21.8deg);
    transform:skewX(-21.8deg);
    .title {
      -moz-transform:skewX(21.8deg);
      -webkit-transform:skewX(21.8deg);
      -o-transform:skewX(21.8deg);
      transform:skewX(21.8deg);
      line-height: 42px;
      padding-left: 18px;
      font-size: 18px;
      font-weight: 700;
    }
  }
}
</style>