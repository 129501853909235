export default {
  name: 'BigIcon',
  props: {
    character: String,
    height: {
      String,
      default: '20px'
    }
  },
  computed: {
    getSrc: function () {
      return `http://image.cnsmash.com/longicon/${this.character}.png`
    }
  }
}