import eventBus from '@/utils/eventBus'
import logoutAction from '@/utils/logout'

import { getGachaToken } from '@/api/user'

export default {
  name: 'CommonHeader',
  data () {
    return {
      login: false,
      username: '',
      drawerVisible: false,
      headSrc: undefined,
      gachaToken: 0
    }
  },
  mounted () {
    // 保存有登录信息
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'))
      this.login = true
      this.username = user.username
      this.headSrc = user.headSrc
      this.getGachaToken()
    }

    // 登录响应
    eventBus.$on('login', (user) => {
      this.login = true
      this.username = user.username
      this.headSrc = user.headSrc
      this.getGachaToken()
    })

    // 登出响应
    eventBus.$on('logout', () => {
      this.login = false
    })
  },
  methods: {
    logout: function () {
      var _this = this
      this.$confirm({
        title: '退出登录',
        content: '确定退出登录？',
        okText: '确认',
        cancelText: '取消',
        onOk: function () {
          _this.login = false
          _this.drawerVisible = false
          logoutAction()
        }
      });
    },
    matchingClick: function () {
      this.drawerVisible = false
      this.$router.push('/matching')
    },
    getGachaToken: function () {
      getGachaToken().then(res => {
        this.gachaToken = res
      })
    }
  }
}