<template>
  <div class="footer">
    <div class="content">
      <div class="logo">
        <img src="/icon/cnsmash_black.png" />
      </div>
      <div class="text">Everyone is here!</div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .footer {
    height: 48px;
    .content {
      height: 100%;
      background-color: #FF3B3B;
      color: black;
      text-align: center;
      width: 100%;
      line-height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      .logo {
        img {
          height: 24px;
        }
      }
      .text {
        margin-left: 18px;
        padding-left: 18px;
        height: 28px;
        line-height: 28px;
        border-left: 2px solid #2D2D2D;
        font-weight: 550;
        font-style: italic;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .footer {
    height: 2rem;
    .content {
      height: 100%;
      background-color: #FF3B3B;
      color: black;
      text-align: center;
      width: 100%;
      line-height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .logo {
        img {
          height: 1.2rem;
        }
      }
      .text {
        margin-left: 1rem;
        padding-left: 1rem;
        height: 1.2rem;
        line-height: 1.2rem;
        border-left: 2px solid #2D2D2D;
        font-weight: 550;
        font-style: italic;
      }
    }
  }
}
</style>