import axios from 'axios'
import { message } from 'ant-design-vue'
import router from '@/router/index'

import eventBus from '@/utils/eventBus'

import { baseUrl } from './base.js'

// 允许cookies
axios.defaults.withCredentials = true

const service = axios.create({
  baseURL: baseUrl,
  timeout: 60000 // request timeout
})

// 发送拦截器
service.interceptors.request.use(function (config) {
  // 填入修改基地址
  let baseUrl = localStorage.getItem('baseUrl')
  if (baseUrl) {
    config.url = baseUrl + config.url
  }
  return config
}, function (error) {
  return false
})

// 接收拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    
    // 权限问题，一律归结为登录问题
    if (res.status == 403) {
      if (localStorage.getItem('user')) {
        message.error('登录已经失效，请重新登录')
        localStorage.removeItem('user')
      } else {
        message.error('请先登录')
      }
      router.push('/home')
      eventBus.$emit('logout')
    } else if (res.status && res.status != 200) {
      message.error(res.error)
    }

    return res
  }
)

export default service