<template>
  <div class="pagination">
    <div class="prev button" @click="prevPage">
      <img src="/icon/left_white.png" />
    </div>
    <div class="page-count">
      <div class="pagination-item"
        v-for="(page, index) in paginationList"
        :key="index"
      >
        <div 
          class="page" 
          v-if="page != -1"
          :class="{'current': current == page}"
          @click="pageChange(page)"
        >{{page}}</div>
        <div class="ellipsis" v-if="page == -1">...</div>
      </div>
    </div>
    <div class="next button" @click="nextPage">
      <img src="/icon/right_white.png" />
    </div>
  </div>  
</template>

<script>
import Pagination from './Pagination.js'
export default {
  ...Pagination
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  @import "Pagination.less";
}
@media screen and (max-width: 750px) {
  @import "PaginationMp.less";
}
</style>