<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <keep-alive>
        <router-view/>
      </keep-alive>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  data () {
    return {
      locale: zhCN
    }
  }
}
</script>


<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  color: #E0E0E0;
  background-color: #1D1D1D;
}

.custom-form-item .ant-form-item-label{
  label {
    color: white;
  }
}

// 滚动条
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #383838;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #000000;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #ff3b3b60;
}

img {
  vertical-align: top;
}

@media screen and (max-width: 750px) {
  .pc {
    display: none;
  }
}

@media screen and (min-width: 750px) {
  .mp {
    display: none;
  }
}

// 全局污染来咯
@import "./global.less";
</style>
