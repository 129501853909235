<template>
  <img v-lazy="getSrc" :style="{height: height}"/>
</template>

<script>
import LongIcon from './LongIcon.js'
export default {
  ...LongIcon
}
</script>

<style scoped>

</style>