<template>
  <div class="common-header">
    <div class="black-row">
      <div class="tall-bar">
        <div class="logo" @click="$router.push({path: '/home'})">
          <img src="/logo.png" />
        </div>
        <div class="navi">
          <div class="block direct" 
            :class="$route.name == 'Matching' ? 'current' : ''">
            <div class="menu">
              <div class="menu-content"
                @click="$router.push('/matching')">
                <div class="cn">在线匹配</div>
                <div class="en">MATCHING</div>
              </div>
            </div>
          </div>
          <div class="block direct" 
            :class="$route.name == 'Freeroom' ? 'current' : ''">
            <div class="menu">
              <div class="menu-content">
                <a-badge :count="freeroomCount">
                  <a href="/freeroom">
                    <div class="cn">自由对战</div>
                    <div class="en">FRIENDLY</div>
                  </a>
                </a-badge>
              </div>
            </div>
          </div>
          <div class="block direct"
            :class="$route.name == 'Tournament' || $route.name == 'TournamentList' ? 'current' : ''">
            <div class="menu">
              <div class="menu-content">
                <a href="/tournamentList">
                  <div class="cn">线上比赛</div>
                  <div class="en">TOURNAMENT</div>
                </a>
              </div>
            </div>
          </div>
          <div class="block dropdown">
            <div class="menu">
              <div class="menu-content">
                <div class="cn">统计</div>
                <div class="en">STATS</div>
              </div>
              <div class="dropdown">
                <div class="item">
                  <a href="/history">
                    <div class="cn">对战记录</div>
                    <div class="en">HISTORY</div>
                  </a>
                </div>
                <div class="item">
                  <a href="/ranking">
                    <div class="cn">排行榜</div>
                    <div class="en">RANKING</div>
                  </a>
                </div>
              </div>
            </div>
            
          </div>
          <div class="block dropdown">
            <div class="menu">
              <div class="menu-content">
                <div class="cn">其他</div>
                <div class="en">OTHERS</div>
              </div>
              <div class="dropdown">
                <div class="item">
                  <a href="/gacha">
                    <div class="cn">徽章抽奖</div>
                    <div class="en">GACHA</div>
                  </a>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
      <div class="user">
        <div class="icon">
        </div>
        <template v-if="login">
          <div class="link">
            <a href="/profile">{{nickName}}</a>
          </div>
          <a-divider type="vertical"/>
          <div class="coin">
            <img src="/icon/coin.png" />
            <span class="count">{{gachaToken}}</span>
          </div>
          <a-divider type="vertical"/>
          <div class="link" @click="logout">
            <a href="#">退出登录</a>
          </div>
        </template>
        <template v-else>
          <div class="link">
            <a href="/login">登录</a>
          </div>
          <a-divider type="vertical"/>
          <div class="link">
            <a href="/register">注册</a>
          </div>
        </template>
      </div>
    </div>
    <div class="custom-row">
      <div class="black">
        <div class="black-bar" />
        <div class="black-tri" />
      </div>
      <div class="red-bar" />
      <div class="red-tri" />
    </div>

  </div>
</template>

<script>
import CommonHeader from './CommonHeader.js'
export default {
  ...CommonHeader
}
</script>

<style lang="less" scoped>
@import "CommonHeader.less";
</style>