export default {
  name: 'Badge',
  props: {
    badge: Object,
    width: {
      String,
      default: '30px'
    }
  },
  computed: {
    getSrc: function () {
      return `http://image.cnsmash.com/badge/${this.badge.uri}.png`
    }
  }
}