import request from './request'
import qs from 'querystring'

const moduleName = 'user/'

export function getMyInfo () {
  return request({
    url: moduleName,
    method: 'GET',
  })
}

export function updateMyRuleset(data) {
  return request({
    url: moduleName + 'rule',
    method: 'POST',
    data
  })
}

export function getMyRule () {
  return request({
    url: moduleName + 'rule',
    method: 'GET'
  })
}

export function getMyDetail () {
  return request({
    url: moduleName + 'detail',
    method: 'GET'
  })
}

export function getInfoById (id) {
  return request({
    url: moduleName + 'id?userId=' + id,
    method: 'GET'
  })
}

export function getFighters (id) {
  return request({
    url: moduleName + 'fighter?userId=' + id,
    method: 'GET'
  })
}

export function addUser (data) {
  return request({
    url: moduleName,
    method: 'POST',
    data
  })
}

export function getGachaToken () {
  return request({
    url: moduleName + 'token',
    method: 'GET'
  })
}

export function getUserPage (data) {
  return request({
    url: moduleName + 'page?' + qs.stringify(data),
    method: 'GET'
  })
}

export function get2022Prize () {
  return request({
    url: moduleName + 'prize2022',
    method: 'GET'
  })
}