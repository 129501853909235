<template>
  <div class="page-layout">
    <!-- 顶部菜单栏 -->
    <div class="page-header">
      <common-header class="pc"/>
      <common-header-mp class="mp"/>
    </div>
    
    <!-- 页面内容 -->
    <div class="page-content">
      <router-view />
    </div>

    <!-- 页脚 -->
    <div class="page-footer">
      <common-footer />
    </div>

  </div>
</template>

<script>
import CommonHeader from '@/layout/CommonHeader/CommonHeader.vue'
import CommonHeaderMp from '@/layout/CommonHeaderMp/CommonHeaderMp.vue'
import CommonFooter from '@/layout/CommonFooter/CommonFooter.vue'
export default {
  components: { CommonHeader, CommonFooter, CommonHeaderMp },
  data () {
    return {
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .pc {
    display: block;
  }
  .mp {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .mp {
    display: block;
  }
  .pc {
    display: none;
  }
}
.page-layout {
  width: 100%;
  height: 100vh;
  background-color: #1D1D1D;
  .page-header {
    width: 100%;
    padding: 0;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    @media screen and (min-width: 750px) {
      height: 42px;
    }
    @media screen and (max-width: 750px) {
      height: 4rem;
    }
  }
  .page-content {
    background-color: white;
    @media screen and (min-width: 750px) {
      padding-top: 42px;
    }
    @media screen and (max-width: 750px) {
      padding-top: 4rem;
    }
    width: 100%;
  }
}
</style>