import CommonLayout from '@/layout/CommonLayout/CommonLayout.vue'

const routes = [
  // {
  //   path: '/test',
  //   name: 'Test',
  //   component: () => import('@/views/Test.vue')
  // },
  // {
  //   path: '/summary',
  //   name: 'Summary',
  //   component: () => import('@/views/Summary/2022/S2022.vue')
  // },
  {
    path: '/',
    name: 'layout',
    component: CommonLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/Home.vue')
      },
      {
        path: '/index',
        name: 'Index',
        redirect: '/home',
        // component: () => import('@/views/Index/Index.vue')
      },
      {
        path: '/main',
        name: 'Main',
        component: () => import('@/views/Main/Main.vue')
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login/Login.vue')
      },
      {
        path: '/login/wechat',
        name: 'WechatLogin',
        component: () => import('@/views/Login/WechatLogin.vue')
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/Register/Register.vue')
      },
      {
        path: '/player/:playerId',
        name: 'Player',
        component: () => import('@/views/Player/Player.vue')
      },
      {
        path: '/player/:playerId/:goto',
        name: 'PlayerComment',
        component: () => import('@/views/Player/Player.vue')
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/Player/PlayerSelf.vue')
      },
      {
        path: '/matching',
        name: 'Matching',
        component: () => import('@/views/Matching/Matching.vue')
      },
      {
        path: '/ranking',
        name: 'Ranking',
        component: () => import('@/views/Ranking/Ranking.vue')
      },
      {
        path: '/history',
        name: 'History',
        component: () => import('@/views/History/History.vue')
      },
      {
        path: '/localsetting',
        name: 'LocalSetting',
        component: () => import('@/views/Setting.vue')
      },
      {
        path: '/battle/:battleId',
        name: 'Matched',
        component: () => import('@/views/Matching/Matched.vue')
      },
      {
        path: '/about',
        name: 'AboutGrand',
        component: () => import('@/views/About/About.vue'),
        children: [
          {
            path: '/about/rule',
            name: 'Rule',
            component: () => import('@/views/About/Rule/Rule.vue')
          },
          {
            path: '/about/help',
            name: 'Help',
            component: () => import('@/views/About/Help/Help.vue')
          },
          {
            path: '/about',
            name: 'About',
            component: () => import('@/views/About/About/About.vue')
          }
        ]
      },
      // 2.0
      {
        path: '/playerList',
        name: 'PlayerList',
        component: () => import('@/views/PlayerList/PlayerList.vue')
      },
      {
        path: '/tournamentList',
        name: 'TournamentList',
        component: () => import('@/views/TournamentList/TournamentList.vue')
      },
      {
        path: '/tournament/:tournamentId',
        name: 'Tournament',
        component: () => import('@/views/Tournament/Tournament.vue')
      },
      {
        path: '/tournamentCreate',
        name: 'TournamentCreate',
        component: () => import('@/views/TournamentCreate/TournamentCreate.vue')
      },
      {
        path: '/tournamentUpdate/:tournamentId',
        name: 'TournamentUpdate',
        component: () => import('@/views/TournamentUpdate/TournamentUpdate.vue')
      },
      {
        path: '/freeroom',
        name: 'Freeroom',
        component: () => import('@/views/Freeroom/Freeroom.vue')
      },
      // 3.0
      {
        path: '/gacha',
        name: 'Gacha',
        component: () => import('@/views/Gacha/Gacha.vue')
      },
      {
        path: '/resultMaker',
        name: 'ResultMaker',
        component: () => import('@/views/ResultMaker/ResultMaker.vue')
      },
      // 4.0
      // {
      //   path: '/street',
      //   name: 'Street',
      //   component: () => import('@/views/Street/Street.vue')
      // },
    ]
  },
  // {
  //   path: '/playground',
  //   name: 'Playground',
  //   component: () => import('@/views/Street/Playground/Playground.vue')
  // }
]

const playgroundRoute = [
  {
    path: '/',
    name: 'Playground',
    component: () => import('@/views/Street/Playground/Playground.vue')
  }
]

export default routes