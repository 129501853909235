<template>
  <div class="key-value" :style="{'width': width}">
    <div class="key">{{keyText}}</div>
    <div class="colon mp">：</div>
    <div class="value">
      {{value}}
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KeyValue',
  props: {
    keyText: String,
    value: String,
    width: {
      type: String,
      default: '100%'
    }
  }
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .key-value {
    height: 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #FF3B3B;
    font-weight: 550;
    .key {
      padding-left: 12px;
      height: 30px;
      width: 45%;
      line-height: 30px;
      background-color: #FF3B3B;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-bottom: 14px solid #2F2F2F;
        border-right: 6px solid #2F2F2F;
        border-top: 15px solid transparent;
        border-left: 6px solid transparent;
      }
    }
    .value {
      padding-left: 12px;
      width: 55%;
    }
  }
}
@media screen and (max-width: 750px) {
  .key-value {
    height: 2rem;
    display: flex;
    align-content: center;
    .key {
      font-weight: 550;
    }
    .value {
      margin-left: 0.6rem;
    }
  }
}
</style>