import Vue from 'vue'
import VueRouter from 'vue-router'

// utils
import loginCheck from '@/utils/loginCheck'
import { compareNow } from '@/utils/timeUtils'

// message
import { message } from 'ant-design-vue'

Vue.use(VueRouter)

// routes
import routes from './routes.js'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// guard
router.beforeEach((to, from, next) => {

  // 在线匹配
  if (to.name == 'Matching') {
    
    // 登录检测
    if (!loginCheck()) {
      message.error('请先登录')
      next(false)
      return
    }

  }

  // 直接进行
  next()

})

export default router
