// api
import { logout } from '@/api/login'

// router
import router from '@/router/index'

// message
import { message } from 'ant-design-vue'

// eventbus
import eventBus from '@/utils/eventBus'

function logoutAction() {
  logout().then(res => {
    if (res.status == 200) {
      message.success('已经退出，请重新登录')
    } else {
      message.error('失败，请重新登录')
    }
    localStorage.removeItem('user')
    router.replace('/index')
    eventBus.$emit('logout')
  })
}

export default logoutAction