<template>
  <img v-lazy="getSrc" :style="{width: width, opacity: opacity}"/>
</template>

<script>
import DiamondIcon from './DiamondIcon.js'
export default {
  ...DiamondIcon
}
</script>

<style scoped>

</style>