import eventBus from '@/utils/eventBus'
import logoutAction from '@/utils/logout'

// api
import { getRoomCount } from '@/api/freeroom'
import { getGachaToken } from '@/api/user'

export default {
  name: 'CommonHeader',
  data () {
    return {
      login: false,
      nickName: '',

      timer: undefined,
      freeroomCount: 0,

      gachaToken: 2000
    }
  },
  mounted () {
    // 保存有登录信息
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'))
      this.login = true
      this.nickName = user.nickName
    }

    // 登录响应
    eventBus.$on('login', (user) => {
      this.login = true
      this.nickName = user.nickName
      this.getGachaToken()
    })

    // 登出响应
    eventBus.$on('logout', () => {
      this.login = false
    })

    // token可能更新响应
    eventBus.$on('token', () => {
      this.getGachaToken()
    })

    if (this.login) {
      this.getGachaToken()
    }

    this.getFreeroomCount()
  },
  methods: {
    logout: function () {
      var _this = this
      this.$confirm({
        title: '退出登录',
        content: '确定退出登录？',
        okText: '确认',
        cancelText: '取消',
        onOk: function () {
          _this.login = false
          logoutAction()
        }
      });
    },
    getFreeroomCount: function () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      getRoomCount().then(res => {
        this.freeroomCount = res.message
        this.timer = setTimeout(this.getFreeroomCount, 300000);
      })
    },
    getGachaToken: function () {
      getGachaToken().then(res => {
        this.gachaToken = res
      })
    }
  }
}