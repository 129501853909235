<template>
  <div class="loading" v-if="loading">
    <div class="content">
      <a-icon type="loading" :style="{'color': '#FF3B3B', 'font-size': width}"/>
      <div class="text">{{text}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    loading: Boolean,
    width: {
      type: String,
      default: '90px'
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #E0E0E0B0;
  .content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    .text {
      color: #FF3B3B;
      margin-top: 12px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>