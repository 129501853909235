import request from './request'
import qs from 'querystring'

export function login (data) {
  return request({
    url: 'login',
    method: 'POST',
    data: qs.stringify(data)
  })
}

export function logout () {
  return request({
    url: 'logout',
    method: 'POST'
  })
}

export function wxLogin (code) {
  return request({
    url: 'account/wechat?code='+code,
    method: 'POST'
  })
}



