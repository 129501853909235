<template>
  <div class="common-header">
    <div class="content">
      <!-- 抬头条条 -->
      <div class="header-bar">
        <div class="menu" @click="drawerVisible = true">
          <div class="menu-icon-bar"/>
          <div class="menu-icon-bar"/>
          <div class="menu-icon-bar"/>
        </div>
        <a href="/">
          <div class="icon">
            <img src="/logo.png" />
          </div>
        </a>
      </div>
      
      <!-- 抽屉 -->
      <a-drawer placement="left"
        :closable="true"
        :visible="drawerVisible"
        @close="drawerVisible = false">
        <div class="drawer">
          <div class="login-box">
            <div v-if="!login" class="no-login">
              <a href="/register">注册</a>
              <a-divider type="vertical" />
              <a href="/login">登录</a>
            </div>
            <div v-else class="login">
              <div class="avatar">
                <a-avatar :size="48"
                  :src="headSrc ? `${$baseUrl}${headSrc}` : ''">{{username[0]}}</a-avatar>
              </div>
              <div class="user-info">
                <div class="username">
                  <a href="/profile">{{username}}</a>
                </div>
                <a-divider/>
                <div class="logout" @click="logout">退出登录</div>
              </div>
            </div>
            <div class="token">
              <img src="/icon/coin.png" />
              <div class="token-count">{{gachaToken}}</div>
            </div>
          </div>
          <div class="menu">
            <!-- 匹配 -->
            <div class="menu-item"
              @click="matchingClick">
              <div class="icon">
                <img src="/icon/match.png"/>
              </div>
              <div class="text">
                <div class="cn">快速匹配</div>
                <div class="en">MATCHING</div>
              </div>
            </div>
            <!-- 记录 -->
            <a href="/freeroom">
              <div class="menu-item">
                <div class="icon">
                  <img src="/icon/training.png"/>
                </div>
                <div class="text">
                  <div class="cn">自由对战</div>
                  <div class="en">FRIENDLY</div>
                </div>
              </div>
            </a>
            <!-- 记录 -->
            <a href="/tournamentList">
              <div class="menu-item">
                <div class="icon">
                  <img src="/icon/tournament.png"/>
                </div>
                <div class="text">
                  <div class="cn">线上比赛</div>
                  <div class="en">TOURNAMENT</div>
                </div>
              </div>
            </a>
            <!-- 记录 -->
            <a href="/history">
              <div class="menu-item">
                <div class="icon">
                  <img src="/icon/history.png"/>
                </div>
                <div class="text">
                  <div class="cn">对战记录</div>
                  <div class="en">HISTORY</div>
                </div>
              </div>
            </a>
            <!-- 排行榜 -->
            <a href="/ranking">
              <div class="menu-item">
                <div class="icon">
                  <img src="/icon/ranking.png"/>
                </div>
                <div class="text">
                  <div class="cn">排行榜</div>
                  <div class="en">RANKING</div>
                </div>
              </div>
            </a>
            <!-- 抽奖 -->
            <a href="/gacha">
              <div class="menu-item">
                <div class="icon">
                  <img src="/icon/gacha.png"/>
                </div>
                <div class="text">
                  <div class="cn">徽章抽奖</div>
                  <div class="en">GACHA</div>
                </div>
              </div>
            </a>
            <!-- 关于 -->
            <a href="/about">
              <div class="menu-item">
                <div class="icon">
                  <img src="/icon/rules.png"/>
                </div>
                <div class="text">
                  <div class="cn">帮助</div>
                  <div class="en">HELP</div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </a-drawer>

    </div>
  </div>
</template>

<script>
import CommonHeaderMp from './CommonHeaderMp.js'
export default {
  ...CommonHeaderMp
}
</script>

<style lang="less" scoped>
  @import "CommonHeaderMp.less"; 
</style>