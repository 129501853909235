import request from './request'
import qs from 'querystring'

const moduleName = 'freeroom/'

export function addFreeroom (data) {
  return request({
    url: moduleName + 'add',
    method: 'POST',
    data
  })
}

export function updateFreeroom (data) {
  return request({
    url: moduleName + 'update',
    method: 'POST',
    data
  })
}

export function getFreeroomList (data) {
  return request({
    url: moduleName + 'list?' + qs.stringify(data),
    method: 'GET',
  })
}

export function getFreeroomDetail (freeroomId) {
  return request({
    url: moduleName + 'detail?id=' + freeroomId,
    method: 'GET'
  })
}

export function getMyRoom () {
  return request({
    url: moduleName + 'myroom',
    method: 'GET'
  })
}

export function shutMyRoom () {
  return request({
    url: moduleName + 'shutme',
    method: 'POST'
  })
}

export function getRoomCount () {
  return request({
    url: moduleName + 'count',
    method: 'GET'
  })
}