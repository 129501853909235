const stageList = [
  {
    name: 'Battlefield',
    file: 'battlefield',
    id: 'bf',
  },
  {
    name: 'Town and City',
    file: 'city',
    id: 'tc'
  },
  {
    name: 'Smashville',
    file: 'village',
    id: 'sv'
  },
  {
    name: 'Final Destination',
    file: 'end',
    id: 'fd'
  },
  {
    name: 'Pokémon Stadium 2',
    file: 'stadium',
    id: 'ps2'
  },
  {
    name: 'Kalos Pokémon League',
    file: 'kalos',
    id: 'kalos'
  },
  {
    name: 'Yoshi\'s Story',
    file: 'cartboard',
    id: 'yoshi'
  },
  {
    name: 'Lylat Cruise',
    file: 'lylatcruise',
    id: 'lylat'
  },
  {
    name: 'Yoshi\'s Island',
    file: 'island'
  },
  {
    name: 'Small Battlefield',
    file: 'battlefields',
    id: 'sbf'
  },
  {
    name: 'Northern Cave',
    file: 'cave',
    id: 'cave'
  },
  {
    name: 'Hollow Baision',
    file: 'castle',
    id: 'castle'
  }
]

export default stageList